
<template>
  <div id="app">
    <!-- <b-navbar toggleable="lg" type="dark" variant="info" id="basicClass">
      <div class="container-fluid">
        <b-navbar-brand href="#"><img src="../public/logo.png" class="NavLogo" alt=""></b-navbar-brand>
      </div>
    </b-navbar> -->
    <!-- <HelloWorld /> -->
    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
// import HelloWorld from './navBar.vue'
import {
  // BNavbar,  
  // BNavbarBrand,
 
} from 'bootstrap-vue'
export default {
  name: 'App',
  components: {
    // HelloWorld
  // BNavbarBrand,
    // BNavbar,
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.multiselect__option, [data-selected] {
    padding: 6px !important;
    min-height: 20px !important;
    line-height: 8px !important;
    font-size: 10px !important;
}
.multiselect__tag {
    font-size: 10px !important;
}
.multiselect__tag-icon {
    line-height: 18px !important;
}
.multiselect__placeholder {
    margin-bottom: 2px !important;
    padding-top: 2px;
}
.multiselect__tags {
    min-height: 30px !important; 
    max-height: 30px !important; 
    padding: 4px 40px 0 4px !important; 
    font-size: 12px !important; 
    overflow: hidden !important
}
.multiselect__select {
    width: 30px !important;
    height: 30px !important;
}
.multiselect__input{
  font-size: 12px !important;
}
.filterLabel{
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

*::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122,153,217)), color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148)));
}
</style>
